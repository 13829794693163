import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { CreateRegister } from "@/@types/register";
interface IRegisterModule {
  _createRegister: CreateRegister;
  _hospitalId: string;
}

@Module({
  dynamic: true,
  store,
  name: "register",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).register,
})
class RegisterModule extends VuexModule implements IRegisterModule {
  public _createRegister: CreateRegister = {
    email: "",
    password: "",
  };
  public _hospitalId = "";

  /**
   * 変更
   * @param info
   */
  @Mutation
  public changeCreateRegister(info: CreateRegister) {
    this._createRegister = info;
  }
  /**
   * 変更
   * @param id
   */
  @Mutation
  public changeHospitalId(id: string) {
    this._hospitalId = id;
  }

  /**
   * 変更
   */
  @Mutation
  public registerReset() {
    this._createRegister = {
      email: "",
      password: "",
    };
  }

  public get createRegister() {
    return this._createRegister || {};
  }
  public get hospitalId() {
    return this._hospitalId || "";
  }
}
export default getModule(RegisterModule);
