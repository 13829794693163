
import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MemberTermsContent from "@/components/MemberTermsContent.vue";
import TermsContent from "@/components/TermsContent.vue";
import RegisterModule from "@/store/register";
import HospitalModule from "@/store/hospital";
import { CreateRegister } from "@/@types/register";
import MemberService from "@/services/members";
import FacilityService from "@/services/facilities";
import { deleteNullProp, getPhoneNumber3, getPostalCode2, parsePhoneNumber } from "@/utils/Util";
import PasswordForm from "@/components/forms/components/PasswordForm.vue";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";
import moment from "moment";

@Component({
  name: "Register",
  components: {
    Header,
    Footer,
    MemberTermsContent,
    TermsContent,
    PasswordForm,
  },
})
export default class Register extends Vue {
  private get createRegister() {
    return RegisterModule.createRegister;
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private confirmPassword = "";

  private onSubscribe = true;

  private async mounted() {
    this.form = this.createRegister;
    try {
      if (this.$route.query.code) {
        const res = await MemberService.getPreRegisterInfo(this.$route.query.code as string);
        const preInfo = res.data.data[0];
        if (preInfo && preInfo.facility_id) {
          HospitalModule.changeHospitalId(preInfo.facility_id);
          const res = await FacilityService.getHospital(preInfo.facility_id as string);
          const hospital = res.data.data[0];
          HospitalModule.changeHospitalName(hospital.name);
        }
        this.form.email = preInfo.email;
      } else {
        // this.$router.push({ name: "Login" });
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private showPass = false;
  private showPassConfirm = false;
  private loading = false;
  private showAlert = false;

  private form: CreateRegister = {
    email: "",
    password: "",
  };

  private async submit() {
    try {
      this.loading = true;
      const payload = deleteNullProp(this.form);
      if (this.onSubscribe) {
        payload.optin_consented_at = moment().format();
      }
      await MemberService.create(payload);
      const user = await Cognito.signIn(this.form.email, this.form.password);
      if (user) {
        AuthModule.changeMe(user);
      } else {
        this.$router.push({ name: "Login" });
      }
      this.$router.push({ name: "RegisterSuccess" });
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      if (error.response.data.error_msg.indexOf("email already")) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
      throw new Error(error);
    }
  }

  private passwordToggle() {
    this.showPass = !this.showPass;
  }
  private confirmPasswordToggle() {
    this.showPassConfirm = !this.showPassConfirm;
  }
}
