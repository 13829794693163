import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=d5b3a15c&"
import script from "./Register.vue?vue&type=script&lang=ts&"
export * from "./Register.vue?vue&type=script&lang=ts&"
import style0 from "./Register.vue?vue&type=style&index=0&id=d5b3a15c&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCheckbox,VImg,VTextField})
